import UniversalTilt from 'universal-tilt.js';
import AOS from 'aos';

// import './_adjectives.js';
// import './_me-image.js';
// import './_scrollMagic.js';

{
  const untilChristmas = () => {
    let date_1 = new Date('12/25/2022');
    let date_2 = new Date();
    
    const days = (date_1, date_2) =>{
        let difference = date_1.getTime() - date_2.getTime();
        let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
        return TotalDays;
    }

    if (date_1 > date_2) {
      document.getElementById('christmas').innerHTML = days(date_1, date_2) +" days until christmas 🎄";
    }
  }

  const init = () => {
    const elems = document.querySelectorAll('.tilt');
    const universalTilt = UniversalTilt.init({
      elements: elems,
      settings: {
        // options...
      },
      callbacks: {
        // callbacks...
      },
    });

    AOS.init({
      disable: 'phone',
      easing: 'ease-in-out',
    });

    untilChristmas();
  };

  init();
}
